<template>
  <div :class="['sidebar', { 'sidebar-closed': isClosed }]" id="sidebar">
    <span v-if="!isClosed" @click="closeSidebar" class="position-absolute close-btn">&times;</span>
    <span v-if="isClosed" @click="openSidebar" class="position-absolute open-btn">&#x2190;</span>
    <div id="logo" class="logo">
      <img src="https://hr.bintorocorp.co.id/assets/img/24x24.svg" alt="logo" />
    </div>
    <div id="listMenu">
      <ul class="list-group list-group-flush" v-if="user.role === 'customer'">
        <li :class="['list-group-item']" v-if="hasPlan">
          <a href @click.prevent="resetBudgetPlan">
            <i class="fa fa-th-list mr-1"></i>
            <span v-if="!isClosed">Paket</span>
          </a>
        </li>
        <li :class="['list-group-item', { active: isDashboardPage }]">
          <router-link to="/" :class="{ active: isDashboardPage }">
            <i class="fa fa-dashboard"></i>
            <span v-if="!isClosed">Dashboard</span>
          </router-link>
        </li>
        <li :class="['list-group-item']">
          <a href @click.prevent="logout">
            <i class="fa fa-sign-out"></i>
            <span v-if="!isClosed">Logout</span>
          </a>
        </li>
      </ul>
      <ul class="list-group list-group-flush" v-else>
        <li :class="['list-group-item']" v-if="hasPlan">
          <a href @click.prevent="resetBudgetPlan">
            <i class="fa fa-th-list mr-1"></i>
            <span v-if="!isClosed">Paket</span>
          </a>
        </li>
        <li :class="['list-group-item']">
          <router-link to="/" :class="{ active: isDashboardPage }">
            <i class="fa fa-dashboard"></i>
            <span v-if="!isClosed">Dashboard</span>
          </router-link>
        </li>
        <li :class="['list-group-item']">
          <router-link to="/room" :class="{ active: isRoomPage }">
            <i class="fa fa-home"></i>
            <span v-if="!isClosed">Room</span>
          </router-link>
        </li>
        <li :class="['list-group-item']">
          <router-link to="/plan" :class="{ active: isPlanPage }">
            <i class="fa fa-map"></i>
            <span v-if="!isClosed">Plan</span>
          </router-link>
        </li>
        <li :class="['list-group-item']">
          <router-link to="/config" :class="{ active: isConfigPage }">
            <i class="fa fa-cog"></i>
            <span v-if="!isClosed">Config</span>
          </router-link>
        </li>
        <li :class="['list-group-item']">
          <router-link to="/account" :class="{ active: isAccountPage }">
            <i class="fa fa-user"></i>
            <span v-if="!isClosed">Account</span>
          </router-link>
        </li>
        <li :class="['list-group-item']">
          <a href @click.prevent="logout">
            <i class="fa fa-sign-out"></i>
            <span v-if="!isClosed">Logout</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapActions } from "vuex";

export default {
  computed: {
    isDashboardPage() {
      return this.$route.meta.parentName === "dashboard";
    },
    isRoomPage() {
      return this.$route.meta.parentName === "room";
    },
    isPlanPage() {
      return this.$route.meta.parentName === "plan";
    },
    isConfigPage() {
      return this.$route.meta.parentName === "config";
    },
    isAccountPage() {
      return this.$route.meta.parentName === "account";
    },
  },
  data: () => ({
    user: {},
    hasPlan: false,
    isClosed: false, // State untuk mengontrol apakah sidebar ditutup sebagian atau tidak
  }),
  async mounted() {
    const dimensiAuth = await Cookies.get("dimensiAuth");
    const user = JSON.parse(dimensiAuth);

    const planDimensi = localStorage.getItem("planDimensi");
    if (planDimensi) {
      this.hasPlan = true;
    }

    this.user = user;
  },
  methods: {
    ...mapActions(["resetCart"]),
    resetBudgetPlan() {
      this.resetCart();
      localStorage.removeItem("planDimensi");
      window.location.href = "/";
    },
    async logout() {
      await Cookies.remove("dimensiAuth");
      window.location.href = "/login";
    },
    closeSidebar() {
      const sidebar = document.getElementById("sidebar");
      sidebar.classList.add("sidebar-closed");
      this.isClosed = true;
    },
    openSidebar() {
      const sidebar = document.getElementById("sidebar");
      sidebar.classList.remove("sidebar-closed");
      this.isClosed = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  width: 250px;
  padding: 40px 35px 0px;
  background: #ededed;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  transition: width 0.3s ease;
  font-family: Arial, sans-serif;
  z-index: 2;
  
}

.sidebar-closed {
  width: 80px;
  overflow: hidden;
  background: #ededed;
}

.close-btn {
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  z-index: 1050;
}

.open-btn {
  top: 10px;
  right: -10px;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  z-index: 1050;
}

.logo img {
  display: block;
  margin: 0 auto;
  width: 30px;
}

#listMenu {
  margin-top: 20px;
  background-color: transparent;
  padding-left: 10px;
  z-index: 3;
}

.list-group {
  background-color: transparent;
  border: none;
}

.list-group-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 8px 0;
  background-color: transparent;
  border: none;
}

.list-group-item i {
  margin-right: 10px;
  font-size: 18px;
}

.list-group-item span {
  display: inline-block;
  margin-left: 5px;
}

.active {
  background: #cda05b !important;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff !important;
  width: 100%;
}

.d-none {
  display: none !important;
}

.sidebar-closed .list-group-item {
  justify-content: center;
  padding-left: 0;
}

.sidebar-closed .list-group-item span {
  display: none;
}

.sidebar-closed #listMenu {
  background-color: transparent;
}
</style>
