<!-- eslint-disable prettier/prettier -->
<template>
  <div id="app">
    <div :class="['d-flex']">
      <list-menu v-if="isAuth" />
      <div id="wrapper" :class="[!isAuth ? 'wrapper-login' : '']">
        <router-view />
      </div>
    </div>
    <div>
      <button
        :class="['btn', 'btn-cart', { shaking: shakingCart }]"
        @click="onCartButtonClick"
        v-if="hiddenButtonCart && budgetPlanSelected?.TypePlan"
      >
        <div class="position-relative">
          <i class="fa fa-shopping-cart" aria-hidden="true"></i>
          <span class="position-absolute start-100 fs-6" v-if="countCart">
            <span>{{ countCart }}</span>
          </span>
        </div>
      </button>
    </div>
    <footer>Dimensi - Bintoro Architect</footer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ListMenu from "@/components/ListMenu.vue";

export default {
  name: "app",
  components: {
    ListMenu,
  },
  computed: {
    ...mapState(["auth", "countCart", "budgetPlanSelected", "shakingCart"]),
    isAuth() {
      const token = this.$root.token;

      if (this.$route.meta.parentName === "login") {
        return false;
      } else {
        return !token ? false : true;
      }
    },
    hiddenButtonCart() {
      if (this.$route.meta.parentName === "dashboard") {
        return this.$route.name !== "cart";
      } else {
        return false;
      }
    },
    isPDFPage() {
      return this.$route.meta.parentName === "pdf";
    },
  },
  mounted() {
    this.loadPlanSelected();
    this.loadItemSelected();
  },
  methods: {
    ...mapActions(["setBudgetPlan", "updateCart"]),
    loadItemSelected() {
      const itemLocal = localStorage.getItem("itemDimensi");

      if (itemLocal) {
        const items = JSON.parse(itemLocal);
        items.forEach((i) => {
          this.updateCart([i]);
        });
      }
    },
    loadPlanSelected() {
      const planLocal = localStorage.getItem("planDimensi");

      if (planLocal) this.setBudgetPlan(JSON.parse(planLocal));
    },
    onCartButtonClick() {
      this.$router.push({
        name: "cart",
      });
    },
  },
};
</script>

<style>
#wrapper {
  padding: 30px;
  padding-left: 280px;
  max-height: 100vh;
  /* width: -webkit-fill-available; */
  width: 100%;
  background: white;
  overflow-y: auto;
}

.wrapper-login {
  padding-left: 0px !important;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  background: #ededed;
  padding: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

button.btn.btn-cart {
  background: #cda05b;
  border-radius: 100%;
  font-size: 25px;
  color: white;
  position: absolute;
  bottom: 55px;
  right: 25px;
  padding: 10px 18px;
}

.shaking {
  animation: shaking 1s;
}

@keyframes shaking {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(-15px, -15px) rotate(5deg);
  }
  50% {
    transform: translate(0, 0) rotate(0eg);
  }
  75% {
    transform: translate(15px, 15px) rotate(-5deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}
</style>
