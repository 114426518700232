import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as jquery from "jquery";
import axios from "axios";
import Cookies from "js-cookie";
import VueImage from "vue-image-component";

import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import Toast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';


window.$ = jquery;
window.Axios = axios;

Vue.config.productionTip = false;
Vue.use(Toast, {
  position: 'top',
  pauseOnHover: true,
});

Vue.component('vue-image', VueImage);

// Force dismiss specific toast
// instance.dismiss();
// Dismiss all opened toast immediately
// Vue.$toast.clear();

new Vue({
  router,
  store,
  render: function (root) {
    return root(App);
  },
  data: () => ({
    mode: "production",
    localURI: 'http://localhost:8080',
    serverURI: 'https://dimensi-api.bintoroarchitect.co.id',
    headersRequestAPI: {},
    assetsPath: '',
    currentURI: '',
    baseAPI: '',
    token: "",
  }),
  mounted() {
    this.currentURI = this.mode === 'production' ? this.serverURI : this.localURI;

    this.baseAPI = `${this.currentURI}/api`;
    this.assetsPath = `${this.currentURI}/uploads`;

    const dimensiAuth = Cookies.get("dimensiAuth")
    this.token = dimensiAuth ? (JSON.parse(dimensiAuth)?._token ?? "") : "";

    this.headersRequestAPI.headers = {
      "Authorization": `Bearer ${this.token}.DIMENSITOKENSECRET_`,
      "Access-Control-Allow-Origin": "*"
    };
  }
}).$mount("#app");
