import vue from "vue";
import Vuex from "vuex";

vue.use(Vuex);

export default new Vuex.Store({
  state: {
    items: [],
    countCart: 0,
    totalSize: 0,
    itemGeneratedPDF: [],
    budgetPlanSelected: undefined,
    budgetPlan: [],
    shakingCart: false,
  },
  actions: {
    saveBudgetPlanState({ state }, payload) {
      state.budgetPlan = payload
    },
    setTotalSizeType({ state }, payload) {
      state.totalSize = payload
    },
    setBudgetPlan({ state }, payload) {
      state.budgetPlanSelected = payload
      localStorage.setItem("planDimensi", JSON.stringify(payload))
    },
    setItemsPDF({ state }, items) {
      state.itemGeneratedPDF = items;
    },
    resetCart({ state }) {
      state.items = [];
      localStorage.setItem("itemDimensi", JSON.stringify(state.items))
    },
    updateCart({ state, commit }, [data, remove = false]) {
      // const haveValue = state.items.filter(item => {
      //   return item.id === data.id
      // })

      // if (!haveValue.length) {
      // state.countCart++
      // state.items.push(data)

      // localStorage.setItem("itemDimensi", JSON.stringify(state.items))
      //   return 1;
      // } else {

      if (remove) {
        const indexOfItem = state.items.findIndex((i) => {
          return i.idx === data.idx;
        });

        state.countCart--
        state.items.splice(indexOfItem, 1)
        localStorage.setItem("itemDimensi", JSON.stringify(state.items))
      } else {
        state.countCart++
        state.items.push(data)

        localStorage.setItem("itemDimensi", JSON.stringify(state.items))
        commit("makeCartShaking")
      }

      // return 0;
      // }
    },
  },
  mutations: {
    makeCartShaking(state) {
      state.shakingCart = true;

      setTimeout(() => {
        state.shakingCart = false;
      }, 1000);
    }
  }
});
